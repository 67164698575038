import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { IFunding } from 'projects/bp-core/src/lib/models/portal/goal/funding.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FundingService {
  constructor(private http: HttpClient) {}
  /**
   * obtencion de listado de fondos
   *
   * @returns {Observable<IFunding[]>} fondos
   */
  list(where?: any, include?: any): Observable<IFunding[]> {
    const href = environment.urlApi + 'fundings/';
    const query: any = {};
    if (where) {
      query.where = where;
    }

    if (include) {
      query.include = include;
    }
    const requestUrl = `${href}?filter=${JSON.stringify(query, null, 2)}`;
    return this.http.get<IFunding[]>(requestUrl);
  }
}
