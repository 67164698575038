<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="text-white dark bg-sidebar print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [inner]="false"
  [autoCollapse]="false"
  [navigation]="navigation?.bp!"
  [opened]="!isScreenSmall"
>
  <!-- <ng-container fuseVerticalNavigationContentHeader>
    <div class="px-4">
      <div
        class="flex flex-row items-center px-4 text-white bg-transparent border rounded-full basis-4/1"
      >
        <ic-icon [icIcon]="icSearch" class="text-slate-400" size="20px"></ic-icon>
        <input
          [formControl]="searchControl"
          class="w-full px-4 py-2 text-white border-0 outline-none placeholder:text-slate-400"
          placeholder="Escriba aquí la opción a buscar.."
          type="text"
        />
      </div>
    </div>
  </ng-container> -->

  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 p-6 pb-0 cursor-pointer">
      <img (click)="bpImgClick()" class="w-40" src="assets/images/logo/logo-betterplan.svg" />
    </div>
  </ng-container>

  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationFooter>
    <!-- User -->
    <div class="flex items-center w-full px-6 py-8 border-t">
      <!-- User rounded button -->
      <bp-user></bp-user>

      <!-- User names and email -->
      <div class="flex flex-col w-full ml-4 overflow-hidden">
        <div
          class="w-full overflow-hidden leading-normal text-current text-ellipsis opacity-80"
          [ngClass]="{ 'whitespace-nowrap': user?.userType !== 'entity' }"
        >
          {{ user?.firstname }} {{ user?.surname }}
        </div>
        @if (user?.userType !== 'entity') {
          <div
            class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50"
          >
            {{ user?.email }}
          </div>
        }
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="grid grid-cols-[auto,1fr,auto] items-center w-full h-16 px-4 z-49 dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden relative"
    [ngClass]="{ shadow: true }"
  >
    <!-- Leading -->
    @if (headerConfig?.leading) {
      <ng-container [ngTemplateOutlet]="headerConfig?.leading ?? null"></ng-container>
    } @else {
      <div>
        <button
          class=""
          mat-icon-button
          color="accent"
          (click)="toggleNavigation('mainNavigation')"
        >
          <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
      </div>
    }

    <!-- Center -->
    @if (headerConfig?.center) {
      <ng-container [ngTemplateOutlet]="headerConfig?.center ?? null"></ng-container>
    } @else {
      <div class="text-center">
        <!-- aquí se añade la clase de centrado de texto -->
        <img
          (click)="bpImgClick()"
          class="block w-6 h-6 mx-auto cursor-pointer md:hidden"
          src="assets/img/auxi/betterplan-b-dark.svg"
          alt="Betterplan"
          title="Betterplan"
          width="24"
          height="24"
        />
      </div>
    }

    <!-- Action -->
    @if (headerConfig?.action) {
      <ng-container [ngTemplateOutlet]="headerConfig?.action ?? null"></ng-container>
    } @else {
      <div class="min-w-[38px]">
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
          <search
            class="cursor-pointer"
            (click)="openSearchDialog()"
            [showKbd]="true"
            [appearance]="'bar'"
          ></search>
        </div>
      </div>
    }
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
      Otherwise, layout changes won't be registered and the view won't be updated! -->
    @if (true) {
      <router-outlet></router-outlet>
    }
  </div>

  <!-- Footer -->
  @if (!routerUrl.includes('users/') && !routerUrl.includes('chat')) {
    <!-- <div
      class="relative flex flex-col items-start justify-center w-full max-w-screen-xl px-4 pt-10 mx-auto flex-0 md:px-6 z-49 dark:bg-transparent print:hidden"
    >
      <div class="flex flex-wrap items-center mb-6 gap-y-3 gap-x-4">
        <a
          href="https://intercom.help/betterplan-advisors/es"
          target="_blank"
          rel="noopener noreferrer"
          class="text-secondary hover:underline"
          >Preguntas frecuentes</a
        >
        <a (click)="openIntercomChat($event)" class="cursor-pointer text-secondary hover:underline">
          Escríbenos
        </a>
        <a
          href="https://storage.googleapis.com/betterplan-prod-public/goal/documents/asesores-betterplan.pdf"
          target="_blank"
          rel="noopener noreferrer"
          class="text-secondary hover:underline"
        >
          Asesores
        </a>
        <a
          [href]="fileUrlBase + config?.termsAndConditionsId + '/?token=' + token"
          target="_blank"
          class="text-secondary hover:underline"
          >Términos y condiciones</a
        >
        <a
          href="https://www.cmfchile.cl/institucional/mercados/entidad.php?auth=&send=&mercado=O&rut=77084652&grupo=&tipoentidad=RGPSF&vig=VI&control=svs&pestania=1"
          target="_blank"
          rel="noopener noreferrer"
          class="text-secondary hover:underline"
          >CMF
        </a>
        <a
          [href]="fileUrlBase + config?.codeOfConductId?.value + '/?token=' + token"
          target="_blank"
          rel="noopener noreferrer"
          class="text-secondary hover:underline"
          >Código de conducta</a
        >
      </div>
      <div class="flex flex-wrap items-center justify-between w-full mb-4 gap-y-6">
        <span class="text-secondary"> {{ currentYear }}, Betterplan Advisors SpA. </span>
        <span class="text-transparent"> Version: {{ env.version }}-{{ env.name }} </span>
      </div>
    </div> -->
  }
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->

<ng-template #searchModal>
  <div class="relative w-full p-10 min-h-10">
    <search (closing)="searchDialogRef.close()" [opened]="true" [appearance]="'bar'"></search>
  </div>
</ng-template>
