
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { UserModel } from '@bp-core/src/lib/proto/common-message.pb';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';
import { UserService } from '@bp-core/src/lib/services/portal/user.service';
import { WVCommunicationService } from '@bp-core/src/lib/services/wvcommunication.service';
import { environment } from '@environment/environment';
import { SharedModule } from 'bp/shared/shared.module';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'bp-user',
  templateUrl: './bp-user.component.html',
  exportAs: 'bp-user',
  standalone: true,
  imports: [
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatIconModule,
    SharedModule,
    RouterModule
],
})
export class BpUserComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  user: UserModel;
  avatarFirstName: string;
  avatarLastName: string;
  isDebugMode = false;
  isAdminUser = false;
  canChangeUser = false;
  canGotoMiPerfil = false;
  avatarImgUrl: string;
  urlBaseFile = environment.urlApiV2 + 'api/Files/';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private wvCommunicationService: WVCommunicationService,
  ) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.isDebugMode = localStorage.getItem('bp_debug') === 'True' ? true : false;

    // Get user
    this.authService.userSubject$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
      if (user) {
        this.user = user;
        //console.warn(user);

        // Set isAdminUser
        this.isAdminUser = this.user.isAdmin;

        // Set canGotoMiPerfil
        this.canGotoMiPerfil = this.user?.firstSteps?.filledFinancialData?.value || false;

        // Set canChangeUser
        if (
          (user.companies && user.companies.length > 1) ||
          (user.manager && user.id != user.manager.id)
        ) {
          this.canChangeUser = true;
        }

        // Set avatarImgUrl
        if (user && user.photoProfileId?.value) {
          const token = localStorage.getItem('access_token');
          this.avatarImgUrl = this.urlBaseFile + user.photoProfileId?.value + '/?token=' + token;
        }

        // Set avatarFirstName && avatarLastName
        if (user?.firstname && user?.surname) {
          if (
            user?.firstname?.split(/\W+/).length >= 2 &&
            user?.surname?.split(/\W+/).length >= 2
          ) {
            this.avatarFirstName = user?.firstname?.replace(/ .*/, '');
            this.avatarLastName = user?.surname?.replace(/ .*/, '');
          } else if (
            user?.firstname.split(/\W+/).length >= 2 &&
            user?.surname.split(/\W+/).length < 2
          ) {
            this.avatarFirstName = user?.firstname.replace(/ .*/, '');
            this.avatarLastName = user?.surname;
          } else if (
            user?.firstname.split(/\W+/).length < 2 &&
            user?.surname.split(/\W+/).length >= 2
          ) {
            this.avatarFirstName = user?.firstname;
            this.avatarLastName = user?.surname.replace(/ .*/, '');
          } else {
            this.avatarFirstName = user?.firstname;
            this.avatarLastName = user?.surname;
          }
        }
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  onAddFile(event: any) {
    if (event.target.files.length === 0) return;

    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) return;

    const file = event.target.files[0];

    this.userService
      .update({ photoProfileFile: file })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        result => {
          this.authService
            .refreshUser()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
        },
        error => {
          console.error(error);
        },
      );
  }

  showDebugPage(): void {
    this.wvCommunicationService.showDebugPage();
  }

  onLogout(): void {
    this.authService.logout();
  }
}
