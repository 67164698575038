import { Injectable } from '@angular/core';
import { GrpcEvent, GrpcMessage, GrpcRequest } from '@ngx-grpc/common';
import { GrpcHandler, GrpcInterceptor } from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGrpcInterceptor implements GrpcInterceptor {
  constructor(private authService: AuthService) {}
  intercept<Q extends GrpcMessage, S extends GrpcMessage>(
    request: GrpcRequest<Q, S>,
    next: GrpcHandler,
  ): Observable<GrpcEvent<S>> {
    const token = localStorage.getItem('access_token');
    if (token) {
      request.requestMetadata.set('Authorization', `Bearer ${token}`);
    }
    const bpEnterAsId = localStorage.getItem('bp-enter-as-id');
    if (bpEnterAsId) {
      request.requestMetadata.set('BPEnterAsId', bpEnterAsId);
    }
    return next.handle(request);
  }
}
