import { Injectable } from '@angular/core';
import {
  BaseListRequest,
  CurrencyIndicatorModel,
  GoalModel,
} from '@bp-core/src/lib/proto/common-message.pb';
import { CurrencyIndicatorGrpcServiceClient } from '@bp-core/src/lib/proto/currency-indicator.pbsc';
import { Empty, StringValue } from '@ngx-grpc/well-known-types';
import dayjs from 'dayjs';
import { Observable, ReplaySubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyIndicatorsService {
  private lastValuesSubject = new ReplaySubject<CurrencyIndicatorModel[]>(1);
  public readonly lastValues$: Observable<
    CurrencyIndicatorModel[]
  > = this.lastValuesSubject.asObservable();

  constructor(private currencyIndicatorGrpcServiceClient: CurrencyIndicatorGrpcServiceClient) {
    this.getLastValues();
  }

  getLastValues() {
    this.currencyIndicatorGrpcServiceClient
      .getLastValues(new Empty())
      .pipe(take(1))
      .subscribe({
        next: result => {
          this.lastValuesSubject.next(result.values || []);
        },
      });
  }

  getListOfValuesForAGoal(goal: GoalModel, from?: Date, to?: Date) {
    return this.currencyIndicatorGrpcServiceClient.list(
      new BaseListRequest({
        where: {
          predicate: new StringValue(
            from && to
              ? {
                  value: `SourceCurrencyId=${goal.currencyId?.value}&&DestinationCurrencyId=${
                    goal.displayCurrencyId?.value
                  }&&Date>="${dayjs(from).format('YYYY-MM-DD')}"&&Date<="${dayjs(to).format(
                    'YYYY-MM-DD',
                  )}"`,
                }
              : {
                  value: `SourceCurrencyId=${goal.currencyId?.value}&&DestinationCurrencyId=${
                    goal.displayCurrencyId?.value
                  }&&Date>="${dayjs(goal.created?.toDate()).format('YYYY-MM-DD')}"`,
                },
          ),
        },
      }),
    );
  }
}
