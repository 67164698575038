import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseLoadingService } from '@fuse/services/loading';
import { Observable, finalize } from 'rxjs';

@Injectable()
export class BpLoadingInterceptor implements HttpInterceptor {
  handleRequestsAutomatically: boolean;

  /**
   * Constructor
   */
  constructor(private _fuseLoadingService: FuseLoadingService) {
    // Subscribe to the auto
    this._fuseLoadingService.auto$.subscribe(value => {
      this.handleRequestsAutomatically = value;
    });
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If the Auto mode is turned off, do nothing
    if (!this.handleRequestsAutomatically) {
      return next.handle(req);
    }
    //Excluir peticiones de refresh token
    const isRefreshTokenRequest = req.url.includes('token');
    const contentType = req.headers.get('Content-Type');
    if (
      !isRefreshTokenRequest &&
      !(
        req.url.includes('assets/icons') ||
        req.url.includes('intercom') ||
        (contentType &&
          (contentType.includes('image/') || contentType === 'image' || contentType === 'icon'))
      )
    ) {
      // Set the loading status to true
      this._fuseLoadingService._setLoadingStatus(true, req.url);

      return next.handle(req).pipe(
        finalize(() => {
          // Set the status to false if there are any errors or the request is completed
          this._fuseLoadingService._setLoadingStatus(false, req.url);
        }),
      );
    }
    return next.handle(req);
  }
}
