import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OAuthService } from 'projects/bp-core/src/lib/external/angular-oauth2-oidc-jwks/public_api';
import { WVCommunicationService } from 'projects/bp-core/src/lib/services/wvcommunication.service';

import { filter, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class BpAuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    private router: Router,
    private oauthService: OAuthService,
    private wvCommunicationService: WVCommunicationService,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.hasDiscoveredSubject$
        .pipe(
          filter(e => e === true),
          take(1),
        )
        .subscribe(async () => {
          if (this.oauthService.hasValidIdToken() || this.oauthService.hasValidAccessToken()) {
            this.wvCommunicationService.isLogin(true);
            try {
              await this.oauthService.refreshToken();

              //  this.oauthService.checkSession();
              return this.authService.userSubject$.pipe(take(1)).subscribe(user => {
                if (user) {
                  return resolve(true);
                } else {
                  this.authService
                    .refreshUser()
                    .pipe(take(1))
                    .subscribe({
                      next: result => {
                        console.log('sesions canActivate refresh');

                        return resolve(true);
                      },
                      error: error => {
                        console.error('isLoginGuard', error);
                        return resolve(false);
                      },
                    });
                }
              });
            } catch (error) {
              console.error(error);
              this.oauthService.initCodeFlow(window.location.href);
              return resolve(false);
            }
          } else {
            console.log(
              'canActivate',

              this.oauthService.hasValidIdToken(),
              this.oauthService.hasValidAccessToken(),
            );
            this.wvCommunicationService.isLogin(false);
            this.oauthService.initCodeFlow(window.location.href);
            return resolve(false);
          }
        });
      // this.authService
      //   .isAuthenticated()
      //   .pipe(take(1))
      //   .subscribe((result) => {
      //     if (result) {
      //       this.authService.userSubject$.pipe(take(1)).subscribe((user) => {
      //         return resolve(true);
      //       });
      //     } else {
      //       this.authService.logout(true);
      //       return resolve(false);
      //     }
      //   });
    });
  }
}
