import { GoalTransactionType } from '../../proto/goal-transaction-type-enums.pb';
import { GoalTransactionActivitiesModel } from '../../proto/portal-goal.pb';

export class ActivityXLSX {
  date: Date;
  detail: string;
  amount: number;
  constructor(activity: GoalTransactionActivitiesModel) {
    // console.warn(activity?.date?.seconds + '000' ?? '');
    // console.warn(new Date(activity?.date?.seconds ?? ''));
    var date = new Date(+(activity?.date?.seconds ?? '' + '000')).toISOString();

    // console.log(date);
    let newDate = new Date(date ?? '').setHours(12);
    this.date = new Date(newDate ?? '');
    console.log(this.date);

    this.detail = `${activity?.goalFrom?.title} - ${this.getDetail(activity.type)}${
      activity.paymentMethod === 3 ? ' Referidos' : ''
    }`;
    this.amount = activity.amount?.value ?? 0;
  }

  getDetail(value: number): any {
    if (value !== undefined) {
      var transactionTypeFormatName = GoalTransactionType[value];
      if (transactionTypeFormatName) {
        if (transactionTypeFormatName.includes('TRANSFER')) return 'Transferencia entre meta';
        return transactionTypeFormatName.includes('BUY') ||
          transactionTypeFormatName.includes('DEPOSIT')
          ? 'Depósito'
          : 'Retiro';
      }
      return value;
    }
    return value;
  }
}
