import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ActivityXLSX } from '../../models/dashboard/activity-xlsx';
import { ICiStatusResponse } from '../../models/portal/goal/ci-status.response';
import { IContractForm } from '../../models/portal/goal/contract.form';
import { IGoalTransaction } from '../../models/portal/goal/goal-transaction.model';

import { IIdentityVerificationForm } from '../../models/portal/goal/identity-verification-form.model';
import { IIdentityVerificationResponse } from '../../models/portal/goal/identity-verification-response.model';
import { ISigningContractForm } from '../../models/portal/goal/signing-contract-form.model';
import { IResponseFiltered } from '../../models/portal/response.filtered.model';
import { IUser } from '../../models/portal/user.model';
import { GoalTransactionActivitiesModel } from '../../proto/portal-goal.pb';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  /**
   * @deprecated Use PortalUserGrpcService.ContractPreview
   */
  previewContract(contract: IContractForm): Observable<ISigningContractForm> {
    const url = environment.urlApi + 'users/me/preview-contract';
    return this.http.post<ISigningContractForm>(url, contract);
  }
  // /**
  //  * funcion para preview contrato
  //  *
  //  * @param {number} userid id del usuario a ver contrato econsult
  //  * @returns {Observable<any>} respuesta del contrato
  //  */
  // previewContractEconsult(userId: number | undefined): Observable<any> {
  //   const url = `${environment.urlApiV2}/template/user/econsult/general-fund-contract/${userId}/preview`;
  //   return this.http.get<any>(url);
  // }
  /**
   * funcion para reenviar contrato al usuario con el link validador de correo
   *
   * @param {string} identityId id de identidad de usuario enviado por correo de validacion al firmar contrato
   * @returns {Observable<ISigningContractForm>} respuesta del contrato
   */
  userResendContractEmailValid(): Observable<any> {
    const url = environment.urlApi + 'users/me/send-contract';
    return this.http.post<any>(url, {});
  }

  /**
   * funcion para confirmar contrato y enviar la informacion a los partners (econsult, vector...)
   *
   * @param {string} identityId id de identidad de usuario enviado por correo de validacion al firmar contrato
   * @returns {Observable<ISigningContractForm>} respuesta del contrato
   */
  userConfirmContract(identityId: string): Observable<ISigningContractForm> {
    const url = environment.urlApi + 'users/confirm-contract';
    return this.http.post<ISigningContractForm>(url, { identity: identityId });
  }
  /**
   * @deprecated Use PortalUserGrpcService.ContractSigning
   */
  signingContract(contract: IContractForm): Observable<ISigningContractForm> {
    const url = environment.urlApi + 'users/me/signing-contract';
    return this.http.post<ISigningContractForm>(url, contract);
  }
  /*funcion saltar paso del advisor
   *
   */
  skipedAdvisor(): Observable<any> {
    const url = environment.urlApi + 'users/me/skipedAdvisor';
    return this.http.post<any>(url, {});
  }
  /**
   * funcion para ver estado del carnet de identidad
   *
   * @returns {Observable<ICiStatusResponse>} respuesta
   */
  ciStatus(): Observable<ICiStatusResponse> {
    const url = environment.urlApi + 'users/me/ci';
    return this.http.get<ICiStatusResponse>(url);
  }
  identityVerification(
    identity: IIdentityVerificationForm,
  ): Observable<IIdentityVerificationResponse> {
    const url = environment.urlApi + 'users/me/identity-verification';
    const formData = new FormData();
    for (const key in identity) {
      if (identity.hasOwnProperty(key)) {
        if (key === 'icFront' || key === 'icBack') {
          // @ts-ignore
          formData.append(key, identity[key], identity[key].name);
          continue;
        }
        // @ts-ignore
        formData.append(key, identity[key]);
      }
    }
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });
    return this.http.request<IIdentityVerificationResponse>(req).pipe(
      map(event => {
        // console.log('event', event);
        if (
          (event.type === HttpEventType.UploadProgress ||
            event.type === HttpEventType.DownloadProgress) &&
          event?.total
        ) {
          // calculate the progress percentage
          return {
            percentage: Math.round((100 * event.loaded) / event?.total),
            notMatch: false,
            notfound: false,
            invalid: false,
            takeAgain: false,
            ok: false,
          };

          // pass the percentage into the progress-stream
        } else if (event instanceof HttpResponse) {
          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          const response = {
            notMatch: false,
            notfound: false,
            invalid: false,
            takeAgain: false,
            ok: true,
          };
          if (event && event.body) {
            response.notMatch = event.body.notMatch;
            response.notfound = event.body.notfound;
            response.invalid = event.body.invalid;
            response.takeAgain = event.body.takeAgain;
            response.ok = event.body.ok;
          }
          return response;
        } else {
          const response = {
            notMatch: false,
            notfound: false,
            invalid: false,
            takeAgain: false,
            ok: false,
          };
          return response;
        }
      }),
    );
  }
  update(user: IUser): Observable<IUser> {
    const url = environment.urlApi + 'users/me';

    const formData = new FormData();
    for (const key in user) {
      if (user.hasOwnProperty(key)) {
        if (key === 'photoProfileFile') {
          // @ts-ignore
          formData.append(key, user[key], user[key].name);
          continue;
        }
        // @ts-ignore
        if (typeof user[key] === 'object') {
          // @ts-ignore
          formData.append(key, JSON.stringify(user[key]));
          continue;
        }
        // @ts-ignore
        formData.append(key, user[key]);
      }
    }

    const req = new HttpRequest('PATCH', url, formData, {
      reportProgress: false,
    });
    // @ts-ignore
    return this.http.request<IUser>(req).pipe(
      map(event => {
        if (
          event.type === HttpEventType.UploadProgress ||
          event.type === HttpEventType.DownloadProgress
        ) {
          return null;
        } else if (event instanceof HttpResponse) {
          return event.body;
        } else {
          return null;
        }
      }),
    );
  }
  // /**
  //  * @deprecated Use PortalUserGrpcService.GetMeFinancialEntities
  //  */
  // financialEntities(): Observable<IFinancialEntity[]> {
  //   const url = environment.urlApi + 'users/me/financialEntities/';

  //   return this.http.get<IFinancialEntity[]>(url);
  // }
  sendLinkValidateCI(): Observable<boolean> {
    const url = environment.urlApi + 'users/me/sendLinkValidateCI/';

    return this.http.get<boolean>(url);
  }
  sendBrochuresPortfolio(idPortfolio: number): Observable<boolean> {
    const url = environment.urlApi + 'users/me/sendBrochuresPortfolio/' + idPortfolio;

    return this.http.get<boolean>(url);
  }
  activities(
    currentPage: number = 1,
    pageSize: number = 10,
    sortKey: string = 'date',
    sortDirection: string = 'DESC',
    fromDate?: Date,
    toDate?: Date,
    type?: string,
    goal?: string,
    currency?: number,
  ): Observable<IResponseFiltered<IGoalTransaction>> {
    currentPage += -1;
    const url = environment.urlApi + 'users/me/activities';
    const query = {
      limit: pageSize,
      skip: currentPage * pageSize,
      where: {
        //@ts-ignore
        and: [],
      },
      order: '',
    };
    if (sortKey && sortDirection) {
      query.order = sortKey + ' ' + sortDirection;
    }
    if (!fromDate && !toDate && !type && !goal) {
      // @ts-ignore
      query.where = null;
    }

    //fuera transacciones metodo de pago rebalance
    // @ts-ignore
    query.where.and.push({ paymentMethod: { nilike: 'rebalance' } });

    if (type) {
      // @ts-ignore
      query.where.and.push({ type });
    }
    if (currency) {
      // @ts-ignore
      query.where.and.push({ currencyId: currency });
    }
    if (goal) {
      // @ts-ignore
      query.where.and.push({ goalId: goal });
    }
    if (fromDate) {
      // @ts-ignore
      query.where.and.push({ date: { gte: fromDate } });
    }
    if (toDate) {
      // @ts-ignore
      query.where.and.push({ date: { lte: toDate } });
    }
    // if (search) {
    //   query.where = {
    //     or: [
    //       { name: { like: '.*' + search + '*.', options: 'i' } },
    //       { email: { like: '.*' + search + '*.', options: 'i' } },
    //       { username: { like: '.*' + search + '*.', options: 'i' } }
    //     ]
    //   };
    // }
    // console.log(query);
    const requestUrl = `${url}?filter=${JSON.stringify(query, null, 2)}`;
    console.log(requestUrl);
    return this.http.get<IResponseFiltered<IGoalTransaction>>(requestUrl);
  }
  exportActivitiesToXlxs(activities: GoalTransactionActivitiesModel[]): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(activities.map(t => new ActivityXLSX(t)));
    ws.A1.v = 'Fecha';
    ws.B1.v = 'Detalle';
    ws.C1.v = 'Monto';
    for (let i = 0; i < activities.length; i++) {
      const cellI = i + 2;
      const cell = ws['A' + cellI];
      delete cell.w; // remove old formatted text
      cell.z = 'd/mm/yy'; // set cell format
    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'actividades.xlsx');
  }

  setIntercomChatVisible(visible: boolean = true) {
    (window as any).Intercom('update', { hide_default_launcher: !visible });
  }
}
