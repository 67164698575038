import {
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BpConfirmationConfig } from 'bp/shared/models/confirmation.types';

@Component({
  selector: 'app-bp-confirmation-modal',
  templateUrl: './bp-confirmation-modal.component.html',
  styles: [
    `
           .fuse-confirmation-dialog-panel {
             padding: 10px !important;
             @screen md {
               @apply w-128;
             }
     
             .mat-mdc-dialog-container {
               .mat-mdc-dialog-surface {
                 padding: 0 !important;
               }
             }
           }
         `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BpConfirmationModalComponent {
  disableConfirmButton = false;
  disableCancelButton = false;

  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;

  /**
   * Constructor
   */
  isBodyTemplate(): boolean {
    return this.data.bodyTemplate ? true : false;
  }

  isBodyComponent(): boolean {
    return this.data.bodyComponent ? true : false;
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BpConfirmationConfig,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<BpConfirmationModalComponent>,
    private el: ElementRef,
  ) {}
  ngAfterViewInit() {
    if (this.data.bodyComponent) {
      const componentRef: ComponentRef<any> = this.data.bodyComponent;
      this.componentContainer.insert(componentRef.hostView);

      const componentElement = componentRef.location.nativeElement;
      componentElement.style.setProperty('display', 'inline-flex', 'important');
      componentElement.style.setProperty('width', '100%', 'important');
      componentElement.style.setProperty('height', '100%', 'important');
    }
  }

  confirmAction() {
    this.disableConfirmButton = true;
    this.data.actions?.confirm?.function!(this.dialogRef);
  }
  cancelAction() {
    this.data.actions?.cancel?.function!(this.dialogRef);
  }
}
