import { Injectable } from '@angular/core';
import { FuseNavigationItem, FuseNavigationService } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { bpNavigation } from 'app/mock-api/common/navigation/data';
import Fuse from 'fuse.js';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class SearchMockApi {
  private readonly _defaultNavigation: FuseNavigationItem[] = bpNavigation;

  /**
   * Constructor
   */
  constructor(
    private _fuseMockApiService: FuseMockApiService,
    private _fuseNavigationService: FuseNavigationService,
  ) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // Get the flat navigation and store it
    const flatNavigation = this._fuseNavigationService.getFlatNavigation(this._defaultNavigation);

    // -----------------------------------------------------------------------------------------------------
    // @ Search results - GET
    // -----------------------------------------------------------------------------------------------------
    this._fuseMockApiService.onPost('api/common/search').reply(({ request }) => {
      // Get the search query
      const query = cloneDeep(request.body.query.toLowerCase());

      // If the search query is an empty string,
      // return an empty array
      if (query === '') {
        return [200, { results: [] }];
      }
      // Normalizar los datos
      const normalizedPages = flatNavigation.map(page => ({
        ...page,
        title: page.title ? this.normalizeString(page.title) : '',
        subtitle: page.subtitle ? this.normalizeString(page.subtitle) : '',
      }));
      // Configurar Fuse.js
      const options = {
        keys: ['title', 'subtitle'],
        threshold: 0.3, // Ajusta este valor según tus necesidades
      };

      const fuse = new Fuse(normalizedPages, options);
      const normalizedQuery = this.normalizeString(query);

      const fuseResults = fuse.search(normalizedQuery).slice(0, 4);

      // Obtener los resultados
      const pagesResults = fuseResults.map(res => res.item);

      // const contactsResults = cloneDeep(this._contacts)
      //     .filter(contact => contact.name.toLowerCase().includes(query));

      // Filter the navigation
      // const pagesResults = cloneDeep(flatNavigation).filter(page => {
      //   const normalizedQuery = this.normalizeString(query);
      //   const normalizedTitle = page.title ? this.normalizeString(page.title) : '';
      //   const normalizedSubtitle = page.subtitle ? this.normalizeString(page.subtitle) : '';

      //   return (
      //     normalizedTitle.includes(normalizedQuery) || normalizedSubtitle.includes(normalizedQuery)
      //   );
      // });

      // const tasksResults = cloneDeep(this._tasks)
      //     .filter(task => task.title.toLowerCase().includes(query));

      // Prepare the results array
      const results = [];

      if (pagesResults.length > 0) {
        // Normalize the results
        pagesResults.forEach((result: any) => {
          // Add the page title as the value
          result.value = result.title;
        });

        // Add to the results
        results.push({
          id: 'pages',
          label: 'Pages',
          results: pagesResults,
        });
      }

      // If there are tasks results...
      // if ( tasksResults.length > 0 )
      // {
      //     // Normalize the results
      //     tasksResults.forEach((result) =>
      //     {
      //         // Add a link
      //         result.link = '/apps/tasks/' + result.id;

      //         // Add the title as the value
      //         result.value = result.title;
      //     });

      //     // Add to the results
      //     results.push({
      //         id     : 'tasks',
      //         label  : 'Tasks',
      //         results: tasksResults,
      //     });
      // }

      // Return the response
      return [200, results];
    });
  }

  normalizeString(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }
}
