import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Empty } from '@ngx-grpc/well-known-types';
import { UserModel } from 'projects/bp-core/src/lib/proto/common-message.pb';
import { MeFinancialEntityModel } from 'projects/bp-core/src/lib/proto/portal-user.pb';
import { PortalUserGrpcServiceClient } from 'projects/bp-core/src/lib/proto/portal-user.pbsc';
import { AuthService } from 'projects/bp-core/src/lib/services/portal/auth.service';
import { RedirectService } from 'projects/bp-core/src/lib/services/portal/redirect.service';
import { BehaviorSubject, take } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FirstStepsService {
  public missingStep = new BehaviorSubject<string>('');
  missingStep$ = this.missingStep.asObservable();
  financialEntities: MeFinancialEntityModel[];
  user: UserModel;
  enabledVector = false;
  signedContract: MeFinancialEntityModel | undefined;
  validStates = ['waitingSendFinancialEntity', 'waitingApproval', 'approved'];
  constructor(
    public router: Router,
    private portalUserGrpcServiceClient: PortalUserGrpcServiceClient,
    private authService: AuthService,
    private redirectService: RedirectService,
  ) {
    this.authService.getCurrentUser().subscribe(currentUser => {
      if (currentUser) {
        this.user = currentUser;
      }
      this.portalUserGrpcServiceClient
        .getMeFinancialEntities(new Empty())
        .pipe(take(1))
        .subscribe(financialEntities => {
          this.financialEntities = financialEntities.values!;
          this.signedContract = this.financialEntities.find(value => value.contract?.signed);

          this.enabledVector = this.financialEntities.find(
            f => f.userFinancialEntity?.financialEntity?.uuid === 'vector',
          )
            ? true
            : false;
        });
    });
  }
  canInvestByFinancialEntity(financialEntityUuid: string) {
    if (this.financialEntities) {
      const meFinancialEntity = this.financialEntities.find(
        value => value.userFinancialEntity?.financialEntity?.uuid == financialEntityUuid,
      );
      if (
        meFinancialEntity?.contract?.signed &&
        this.validStates.includes(meFinancialEntity.contract.state) &&
        this.user.icValidated
      )
        return true;
      return false;
    }
    return false;
  }
}
