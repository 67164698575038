/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './portal-user.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as file_message007 from './file-message.pb';
import * as common_message008 from './common-message.pb';
import { GRPC_PORTAL_USER_GRPC_SERVICE_CLIENT_SETTINGS } from './portal-user.pbconf';
/**
 * Service client implementation for portal_user.PortalUserGrpcService
 */
@Injectable({ providedIn: 'any' })
export class PortalUserGrpcServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /portal_user.PortalUserGrpcService/CancelTransactionRequest
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CancelTransactionRequestResponse>>
     */
    cancelTransactionRequest: (
      requestData: thisProto.CancelTransactionRequestRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CancelTransactionRequestResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/CancelTransactionRequest',
        requestData,
        requestMetadata,
        requestClass: thisProto.CancelTransactionRequestRequest,
        responseClass: thisProto.CancelTransactionRequestResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetUserResultCalculate
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserResultCalculateModel>>
     */
    getUserResultCalculate: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserResultCalculateModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/GetUserResultCalculate',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: common_message008.UserResultCalculateModel
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/SaveFinanceAnswers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserModel>>
     */
    saveFinanceAnswers: (
      requestData: thisProto.AnswerFirstStepRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/SaveFinanceAnswers',
        requestData,
        requestMetadata,
        requestClass: thisProto.AnswerFirstStepRequest,
        responseClass: common_message008.UserModel
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/CalculateSaveRiskProfile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserModel>>
     */
    calculateSaveRiskProfile: (
      requestData: thisProto.AnswerFirstStepRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/CalculateSaveRiskProfile',
        requestData,
        requestMetadata,
        requestClass: thisProto.AnswerFirstStepRequest,
        responseClass: common_message008.UserModel
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/UpdateUser
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserModel>>
     */
    updateUser: (
      requestData: common_message008.UserModel,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/UpdateUser',
        requestData,
        requestMetadata,
        requestClass: common_message008.UserModel,
        responseClass: common_message008.UserModel
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/UpdateUserInformation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.UserInformationModel>>
     */
    updateUserInformation: (
      requestData: common_message008.UserInformationModel,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.UserInformationModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/UpdateUserInformation',
        requestData,
        requestMetadata,
        requestClass: common_message008.UserInformationModel,
        responseClass: common_message008.UserInformationModel
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/ContractPreview
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ContractPreviewResponse>>
     */
    contractPreview: (
      requestData: thisProto.ContractPreviewRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ContractPreviewResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/ContractPreview',
        requestData,
        requestMetadata,
        requestClass: thisProto.ContractPreviewRequest,
        responseClass: thisProto.ContractPreviewResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/SendContractAndEmailValidation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SendContractAndEmailValidationResponse>>
     */
    sendContractAndEmailValidation: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.SendContractAndEmailValidationResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_user.PortalUserGrpcService/SendContractAndEmailValidation',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.SendContractAndEmailValidationResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/ContractSigning
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ContractSigningResponse>>
     */
    contractSigning: (
      requestData: thisProto.ContractSigningRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ContractSigningResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/ContractSigning',
        requestData,
        requestMetadata,
        requestClass: thisProto.ContractSigningRequest,
        responseClass: thisProto.ContractSigningResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/ConfirmContractAndEmail
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ConfirmContractAndEmailResponse>>
     */
    confirmContractAndEmail: (
      requestData: thisProto.ConfirmContractAndEmailRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ConfirmContractAndEmailResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/ConfirmContractAndEmail',
        requestData,
        requestMetadata,
        requestClass: thisProto.ConfirmContractAndEmailRequest,
        responseClass: thisProto.ConfirmContractAndEmailResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/UploadCI
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UploadCIResponse>>
     */
    uploadCI: (
      requestData: thisProto.UploadCIRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UploadCIResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/UploadCI',
        requestData,
        requestMetadata,
        requestClass: thisProto.UploadCIRequest,
        responseClass: thisProto.UploadCIResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/SignReferralContract
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SignReferralContractResponse>>
     */
    signReferralContract: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.SignReferralContractResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/SignReferralContract',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.SignReferralContractResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/ReferralContractSigned
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ReferralContractSignedResponse>>
     */
    referralContractSigned: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ReferralContractSignedResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/ReferralContractSigned',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.ReferralContractSignedResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeFinancialEntities
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeFinancialEntitiesResponse>>
     */
    getMeFinancialEntities: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeFinancialEntitiesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/GetMeFinancialEntities',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.GetMeFinancialEntitiesResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/ListCommuneWithRegion
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListCommuneWithRegionResponse>>
     */
    listCommuneWithRegion: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListCommuneWithRegionResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/ListCommuneWithRegion',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.ListCommuneWithRegionResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetConfig
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.ConfigModel>>
     */
    getConfig: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.ConfigModel>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/GetConfig',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: common_message008.ConfigModel
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeDocuments
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeDocumentsResponse>>
     */
    getMeDocuments: (
      requestData: thisProto.GetMeDocumentsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeDocumentsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/GetMeDocuments',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeDocumentsRequest,
        responseClass: thisProto.GetMeDocumentsResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeSummaryNotificationsTransactionsPending
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeSummaryNotificationsTransactionsPendingResponse>>
     */
    getMeSummaryNotificationsTransactionsPending: (
      requestData: thisProto.GetMeNotificationsTransactionsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetMeSummaryNotificationsTransactionsPendingResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_user.PortalUserGrpcService/GetMeSummaryNotificationsTransactionsPending',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeNotificationsTransactionsRequest,
        responseClass:
          thisProto.GetMeSummaryNotificationsTransactionsPendingResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeNotificationsTransactionsPending
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeNotificationsTransactionsPendingResponse>>
     */
    getMeNotificationsTransactionsPending: (
      requestData: thisProto.GetMeNotificationsTransactionsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetMeNotificationsTransactionsPendingResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_user.PortalUserGrpcService/GetMeNotificationsTransactionsPending',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeNotificationsTransactionsRequest,
        responseClass: thisProto.GetMeNotificationsTransactionsPendingResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeActivities
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeActivitiesResponse>>
     */
    getMeActivities: (
      requestData: thisProto.GetMeActivitiesRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeActivitiesResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/GetMeActivities',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeActivitiesRequest,
        responseClass: thisProto.GetMeActivitiesResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeActivityDetail
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeActivityDetailResponse>>
     */
    getMeActivityDetail: (
      requestData: thisProto.GetMeActivityDetailRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeActivityDetailResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/GetMeActivityDetail',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetMeActivityDetailRequest,
        responseClass: thisProto.GetMeActivityDetailResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/ShowAdvisorPopup
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.BaseResponse>>
     */
    showAdvisorPopup: (
      requestData: thisProto.ShowAdvisorPopupRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/ShowAdvisorPopup',
        requestData,
        requestMetadata,
        requestClass: thisProto.ShowAdvisorPopupRequest,
        responseClass: common_message008.BaseResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeAnswerFinancialInformation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeAnswerFinancialInformationResponse>>
     */
    getMeAnswerFinancialInformation: (
      requestData: googleProtobuf006.Empty,
      requestMetadata = new GrpcMetadata()
    ): Observable<
      GrpcEvent<thisProto.GetMeAnswerFinancialInformationResponse>
    > => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path:
          '/portal_user.PortalUserGrpcService/GetMeAnswerFinancialInformation',
        requestData,
        requestMetadata,
        requestClass: googleProtobuf006.Empty,
        responseClass: thisProto.GetMeAnswerFinancialInformationResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/UpdateProfile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.BaseResponse>>
     */
    updateProfile: (
      requestData: thisProto.UpdateUserProfileRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/UpdateProfile',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateUserProfileRequest,
        responseClass: common_message008.BaseResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/AddBankAccount
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<common_message008.BaseResponse>>
     */
    addBankAccount: (
      requestData: thisProto.AddBankAccountRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<common_message008.BaseResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/AddBankAccount',
        requestData,
        requestMetadata,
        requestClass: thisProto.AddBankAccountRequest,
        responseClass: common_message008.BaseResponse
      });
    },
    /**
     * Unary call: /portal_user.PortalUserGrpcService/GetMeFirstActivity
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetMeFirstActivityResponse>>
     */
    getMeFirstActivity: (
      requestData: common_message008.BaseGetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetMeFirstActivityResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/portal_user.PortalUserGrpcService/GetMeFirstActivity',
        requestData,
        requestMetadata,
        requestClass: common_message008.BaseGetRequest,
        responseClass: thisProto.GetMeFirstActivityResponse
      });
    }
  };

  constructor(
    @Optional()
    @Inject(GRPC_PORTAL_USER_GRPC_SERVICE_CLIENT_SETTINGS)
    settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'portal_user.PortalUserGrpcService',
      settings
    );
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/CancelTransactionRequest
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CancelTransactionRequestResponse>
   */
  cancelTransactionRequest(
    requestData: thisProto.CancelTransactionRequestRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CancelTransactionRequestResponse> {
    return this.$raw
      .cancelTransactionRequest(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetUserResultCalculate
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserResultCalculateModel>
   */
  getUserResultCalculate(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserResultCalculateModel> {
    return this.$raw
      .getUserResultCalculate(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/SaveFinanceAnswers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserModel>
   */
  saveFinanceAnswers(
    requestData: thisProto.AnswerFirstStepRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserModel> {
    return this.$raw
      .saveFinanceAnswers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/CalculateSaveRiskProfile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserModel>
   */
  calculateSaveRiskProfile(
    requestData: thisProto.AnswerFirstStepRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserModel> {
    return this.$raw
      .calculateSaveRiskProfile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/UpdateUser
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserModel>
   */
  updateUser(
    requestData: common_message008.UserModel,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserModel> {
    return this.$raw
      .updateUser(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/UpdateUserInformation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.UserInformationModel>
   */
  updateUserInformation(
    requestData: common_message008.UserInformationModel,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.UserInformationModel> {
    return this.$raw
      .updateUserInformation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/ContractPreview
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ContractPreviewResponse>
   */
  contractPreview(
    requestData: thisProto.ContractPreviewRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ContractPreviewResponse> {
    return this.$raw
      .contractPreview(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/SendContractAndEmailValidation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SendContractAndEmailValidationResponse>
   */
  sendContractAndEmailValidation(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SendContractAndEmailValidationResponse> {
    return this.$raw
      .sendContractAndEmailValidation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/ContractSigning
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ContractSigningResponse>
   */
  contractSigning(
    requestData: thisProto.ContractSigningRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ContractSigningResponse> {
    return this.$raw
      .contractSigning(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/ConfirmContractAndEmail
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ConfirmContractAndEmailResponse>
   */
  confirmContractAndEmail(
    requestData: thisProto.ConfirmContractAndEmailRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ConfirmContractAndEmailResponse> {
    return this.$raw
      .confirmContractAndEmail(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/UploadCI
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UploadCIResponse>
   */
  uploadCI(
    requestData: thisProto.UploadCIRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UploadCIResponse> {
    return this.$raw
      .uploadCI(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/SignReferralContract
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SignReferralContractResponse>
   */
  signReferralContract(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.SignReferralContractResponse> {
    return this.$raw
      .signReferralContract(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/ReferralContractSigned
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ReferralContractSignedResponse>
   */
  referralContractSigned(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ReferralContractSignedResponse> {
    return this.$raw
      .referralContractSigned(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeFinancialEntities
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeFinancialEntitiesResponse>
   */
  getMeFinancialEntities(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeFinancialEntitiesResponse> {
    return this.$raw
      .getMeFinancialEntities(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/ListCommuneWithRegion
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListCommuneWithRegionResponse>
   */
  listCommuneWithRegion(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListCommuneWithRegionResponse> {
    return this.$raw
      .listCommuneWithRegion(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetConfig
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.ConfigModel>
   */
  getConfig(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.ConfigModel> {
    return this.$raw
      .getConfig(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeDocuments
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeDocumentsResponse>
   */
  getMeDocuments(
    requestData: thisProto.GetMeDocumentsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeDocumentsResponse> {
    return this.$raw
      .getMeDocuments(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeSummaryNotificationsTransactionsPending
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeSummaryNotificationsTransactionsPendingResponse>
   */
  getMeSummaryNotificationsTransactionsPending(
    requestData: thisProto.GetMeNotificationsTransactionsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<
    thisProto.GetMeSummaryNotificationsTransactionsPendingResponse
  > {
    return this.$raw
      .getMeSummaryNotificationsTransactionsPending(
        requestData,
        requestMetadata
      )
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeNotificationsTransactionsPending
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeNotificationsTransactionsPendingResponse>
   */
  getMeNotificationsTransactionsPending(
    requestData: thisProto.GetMeNotificationsTransactionsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeNotificationsTransactionsPendingResponse> {
    return this.$raw
      .getMeNotificationsTransactionsPending(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeActivities
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeActivitiesResponse>
   */
  getMeActivities(
    requestData: thisProto.GetMeActivitiesRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeActivitiesResponse> {
    return this.$raw
      .getMeActivities(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeActivityDetail
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeActivityDetailResponse>
   */
  getMeActivityDetail(
    requestData: thisProto.GetMeActivityDetailRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeActivityDetailResponse> {
    return this.$raw
      .getMeActivityDetail(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/ShowAdvisorPopup
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.BaseResponse>
   */
  showAdvisorPopup(
    requestData: thisProto.ShowAdvisorPopupRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.BaseResponse> {
    return this.$raw
      .showAdvisorPopup(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeAnswerFinancialInformation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeAnswerFinancialInformationResponse>
   */
  getMeAnswerFinancialInformation(
    requestData: googleProtobuf006.Empty,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeAnswerFinancialInformationResponse> {
    return this.$raw
      .getMeAnswerFinancialInformation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/UpdateProfile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.BaseResponse>
   */
  updateProfile(
    requestData: thisProto.UpdateUserProfileRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.BaseResponse> {
    return this.$raw
      .updateProfile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/AddBankAccount
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<common_message008.BaseResponse>
   */
  addBankAccount(
    requestData: thisProto.AddBankAccountRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<common_message008.BaseResponse> {
    return this.$raw
      .addBankAccount(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/portal_user.PortalUserGrpcService/GetMeFirstActivity
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetMeFirstActivityResponse>
   */
  getMeFirstActivity(
    requestData: common_message008.BaseGetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetMeFirstActivityResponse> {
    return this.$raw
      .getMeFirstActivity(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
