import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  // private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private currentVersion = environment.version;
  private reloadCounts = 0;
  constructor(private http: HttpClient) {}
  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url: string, frequency = 1000 * 60 * 30) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);

    this.checkVersion(url);
  }
  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string) {
    return;

    this.currentVersion = environment.version;
    if (!isNaN(parseInt(localStorage.getItem('reload_count')!))) {
      this.reloadCounts = parseInt(localStorage.getItem('reload_count')!);
    }

    // timestamp these requests to invalidate caches
    this.http
      .get(url + '?t=' + new Date().getTime())
      .pipe(take(1))
      .subscribe(
        (response: any) => {
          if (!response) {
            console.log('No version');
            return;
          }
          const version = response.version;
          const versionChanged = this.hasVersionChange(this.currentVersion, version);
          // Si hay nueva version, actualizar
          if (versionChanged) {
            console.info('version distinta se recargara el sitio', [
              this.currentVersion,
              version,
              this.reloadCounts,
            ]);

            if (this.reloadCounts > 3) {
              //no seguir recargando y resetear
              console.log('Estamos en loop, dejamos de recargar la web');
              this.reloadCounts = 0;
              localStorage.setItem('reload_count', '0');
              return;
            }

            if ('caches' in window) {
              caches
                .keys()
                .then(names => {
                  return Promise.all(names.map(name => caches.delete(name)));
                })
                .then(() => {
                  this.currentVersion = version;
                  this.reloadCounts++;
                  localStorage.setItem('reload_count', this.reloadCounts.toString());
                  // @ts-ignore
                  document.location.reload(true);
                });
            }
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentVersion = version;
        },
        err => {
          console.error(err, 'No fue posible obtener información de version');
        },
      );
  }

  private hasVersionChange(currentVersion: string, newVersion: string) {
    return currentVersion !== newVersion;
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash: string, newHash: string) {
    /*if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }*/
    return currentHash !== newHash;
  }
}
