import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'time-period-select',
  templateUrl: './time-period-select.component.html',
  styleUrls: ['./time-period-select.component.scss'],
})
export class TimePeriodSelectComponent implements OnInit, OnDestroy, OnChanges {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  formControl = new UntypedFormControl(1);
  options: any[] = [
    {
      id: 1,
      text: 'Desde el comienzo',
    },
    {
      id: 2,
      text: 'Últimos 24 meses',
    },
    {
      id: 3,
      text: 'Últimos 12 meses',
    },
    {
      id: 4,
      text: 'Últimos 3 meses',
    },
    {
      id: 5,
      text: 'Último mes',
    },
    {
      id: 6,
      text: 'Año actual',
    },
  ];
  @Input() initialDate: string | undefined;
  @Input() initialValue?: number = 1;
  @Output() valueChange = new EventEmitter<{
    value: number;
    dateFrom: string;
    initialDate?: string;
    labels?: string[];
  }>();
  ngOnChanges(changes: SimpleChanges): void {
    this.formControl.setValue(changes.initialValue.currentValue ?? 1, { emitEvent: false });
  }
  ngOnInit() {
    this.formControl.setValue(this.initialValue ?? 1);
    this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value: number) => {
      let dateFrom;

      switch (value) {
        case 1:
          dateFrom = this.initialDate;
          break;
        case 2:
          dateFrom = dayjs()
            .subtract(2, 'year')
            .format('YYYY-MM-DD');
          break;
        case 3:
          dateFrom = dayjs()
            .subtract(1, 'year')
            .format('YYYY-MM-DD');
          break;
        case 4:
          dateFrom = dayjs()
            .subtract(3, 'month')
            .format('YYYY-MM-DD');
          break;
        case 5:
          dateFrom = dayjs()
            .subtract(1, 'month')
            .format('YYYY-MM-DD');
          break;
        case 6:
          dateFrom = dayjs()
            .startOf('year')
            .format('YYYY-MM-DD');
          break;
        default:
          break;
      }

      // console.warn('------', dateFrom);

      this.valueChange.emit({
        value: value,
        dateFrom: dateFrom,
        initialDate: this.initialDate,
        labels: [this.options.find(x => x.id === value)?.text],
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }
}
