import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '@bp-core/src/lib/proto/common-message.pb';
import { MeFinancialEntityModel } from '@bp-core/src/lib/proto/portal-user.pb';
import { PortalUserGrpcServiceClient } from '@bp-core/src/lib/proto/portal-user.pbsc';
import { UserGrpcServiceClient } from '@bp-core/src/lib/proto/user.pbsc';
import { Empty } from '@ngx-grpc/well-known-types';
import { AuthService } from 'projects/bp-core/src/lib/services/portal/auth.service';
import { ReplaySubject, of, switchMap, take } from 'rxjs';
import { CurrentStepInfo } from '../models/current-step';
import { Step } from '../models/first-step.enum';
import { UserFinancialEntityInfo } from '../models/user-financial-entity.model';
@Injectable({
  providedIn: 'root',
})
export class UserStateService {
  private currentStepSubject = new ReplaySubject<CurrentStepInfo>(1);
  public readonly currentStep$ = this.currentStepSubject.asObservable();
  private user: UserModel;
  private totalSteps = 4;
  private financialEntities: MeFinancialEntityModel[];
  private signedContractsEntity: MeFinancialEntityModel[] | undefined;
  private financialEntityInfoSubject = new ReplaySubject<UserFinancialEntityInfo>(1);
  public readonly financialEntityInfo$ = this.financialEntityInfoSubject.asObservable();

  private advisorSubject = new ReplaySubject<UserModel>(1);
  public readonly advisor$ = this.advisorSubject.asObservable();
  constructor(
    public router: Router,
    private portalUserGrpcServiceClient: PortalUserGrpcServiceClient,
    private authService: AuthService,
    private userGrpcServiceClient: UserGrpcServiceClient,
  ) {
    this.initializeService();
  }
  private initializeService() {
    this.authService
      .getCurrentUser()
      .pipe(
        switchMap(currentUser => {
          if (currentUser) {
            this.user = currentUser;
            // Retorna el observable de getMeFinancialEntities
            return this.portalUserGrpcServiceClient
              .getMeFinancialEntities(new Empty())
              .pipe(take(1));
          } else {
            // Si no hay un usuario actual, emite un observable que completa inmediatamente
            return of(null);
          }
        }),
      )
      .subscribe(financialEntities => this.handleFinancialEntities(financialEntities?.values));
    this.userGrpcServiceClient.getMeAdvisor(new Empty()).subscribe(advisor => {
      this.advisorSubject.next(advisor);
    });
  }

  private handleFinancialEntities(financialEntities: MeFinancialEntityModel[] | undefined) {
    if (financialEntities) {
      this.financialEntities = financialEntities;

      // filtramos los contratos firmados
      this.signedContractsEntity = this.financialEntities.filter(value => value.contract?.signed);

      this.financialEntityInfoSubject.next({
        signedContractsEntity: this.signedContractsEntity,
        financialEntities: this.financialEntities,
      });

      // construimos y emitimos el CurrentStepInfo
      const currentStepInfo = this.calculateCurrentStepInfo();
      this.currentStepSubject.next(currentStepInfo);
    }
  }

  private calculateCurrentStepInfo(): CurrentStepInfo {
    let currentStepInfo: CurrentStepInfo = {
      currentStep: Step.FIRST,
      canDeposit: false,
      canTransfer: false,
      canWithdraw: false,
      progress: 0,
      extraConditions: {
        emailConfirmed: this.user.emailVerified,
        hasGoals: this.user.goals?.length! > 0,
      },
    };
    if (this.user.userType === 'entity') {
      currentStepInfo = this.updateCurrentStepInfo(currentStepInfo, Step.SECOND);
    }

    // Esta en el primer paso (segundo paso para entidades)
    if (
      !this.user.firstSteps?.skipedAdvisor?.value &&
      !this.user.firstSteps?.scheduledAdvisor?.value
    ) {
      return currentStepInfo;
    }

    // Esta en el segundo paso (solo para usuarios)
    if (!this.user.icValidated && this.user.userType !== 'entity') {
      return this.updateCurrentStepInfo(currentStepInfo, Step.SECOND);
    }

    // Esta en el tercer paso
    if (!this.user.firstSteps?.contract?.value || !this.user.emailVerified) {
      return this.updateCurrentStepInfo(currentStepInfo, Step.THIRD);
    }

    // Esta en el cuarto paso
    if (!this.user.firstSteps.invested?.value) {
      currentStepInfo = this.updateCurrentStepInfo(currentStepInfo, Step.FOURTH);
      if (
        currentStepInfo.extraConditions?.emailConfirmed &&
        currentStepInfo.extraConditions?.hasGoals
      ) {
        currentStepInfo.canDeposit = true;
        currentStepInfo.canTransfer = true;
        currentStepInfo.canWithdraw = true;
      }
      return currentStepInfo;
    }

    // Acabo el proceso
    return this.updateCurrentStepInfo(currentStepInfo, Step.DONE, true);
  }

  private updateCurrentStepInfo(
    currentStepInfo: CurrentStepInfo,
    currentStep: Step,
    canOperate = false,
  ): CurrentStepInfo {
    // Si alguna vez existe una diferencia entre canDeposit, canTransfer y canWithdraw
    return {
      ...currentStepInfo,
      currentStep,
      progress: currentStep === Step.DONE ? 100 : this.getProgress(currentStep),
      canDeposit: canOperate,
      canTransfer: canOperate,
      canWithdraw: canOperate,
    };
  }

  private getProgress(currentStep: Step): number {
    return (currentStep - 1) / this.totalSteps;
  }
}
