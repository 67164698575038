import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/**
 * A service that manages navigation and context information for an Angular application.
 * @class BackService
 * @exports BackService
 *
 * @property {string[]} contexts - An array of strings representing the navigation contexts.
 * @property {BehaviorSubject<boolean>} onBackSubject - A BehaviorSubject that emits a boolean value indicating whether the user has navigated back.
 * @property {Observable<boolean>} onBack - An observable that emits a boolean value indicating whether the user has navigated back.
 * @property {BehaviorSubject<boolean>} isFirstStepSubject - A BehaviorSubject that emits a boolean value indicating whether the current step is the first step in a flow.
 * @property {Observable<boolean>} isFirstStepObs - An observable that emits a boolean value indicating whether the current step is the first step in a flow.
 * @property {boolean} noHeader - A boolean value indicating whether the current view should include a header.
 *
 * @constructor
 */
@Injectable({
  providedIn: 'root',
})
export class BackService {
  private contexts: string[] = [];
  private onBackSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly onBack: Observable<boolean> = this.onBackSubject.asObservable();
  private isFirstStepSubject: BehaviorSubject<boolean | undefined> = new BehaviorSubject<
    boolean | undefined
  >(true);
  public readonly isFirstStepObs: Observable<
    boolean | undefined
  > = this.isFirstStepSubject.asObservable();
  public noHeader: boolean = false;

  constructor() {
    console.log('BackService constructor');
  }

  /**
   * Adds a context to the navigation context stack.
   * @param {*} context - The context to add.
   * @memberof BackService
   */
  addContext(context: any) {
    this.contexts.push(context);
  }

  /**
   * Navigates back to the previous context in the navigation context stack.
   * @param {boolean} noActivate - A boolean value indicating whether to navigate back without activating the previous context.
   * @memberof BackService
   */
  back(noActivate?: boolean) {
    if (noActivate) {
      this.onBackSubject.next(false);
      return;
    }
    this.onBackSubject.next(true);
  }

  /**
   * Removes the last context from the navigation context stack.
   * @memberof BackService
   */
  removeContext() {
    if (this.contexts.length > 0) this.contexts.pop();
    this.onBackSubject.next(false);
  }

  /**
   * Checks whether a given context is the current context.
   * @param {string} context - The context to check.
   * @return {boolean} A boolean value indicating whether the given context is the current context.
   * @memberof BackService
   */
  checkContext(context: string): boolean {
    return this.contexts[this.contexts.length - 1] == context;
  }

  /**
   * Checks whether the current step is the first step in a flow.
   * @param {number} step - The current step number.
   * @param {boolean} isFirstFlow - A boolean value indicating whether this is the first step in a new flow.
   * @memberof BackService
   */
  isFirstStep(step: number, isFirstFlow?: boolean) {
    if (step == 0) {
      if (this.contexts.length == 1 || isFirstFlow) this.isFirstStepSubject.next(true);
      return;
    }
    this.isFirstStepSubject.next(false);
  }

  /**
   * Initializes the service by resetting all properties.
   * @memberof BackService
   */
  initializeService() {
    this.noHeader = false;
    this.contexts = [];
    this.isFirstStepSubject.next(true);
    this.onBackSubject.next(false);
  }

  /**
   * Updates the noHeader property after a delay to avoid race conditions.
   * @param {boolean} value - The new value of the noHeader property.
   * @memberof BackService
   */
  updateNoHeader(value: boolean) {
    setTimeout(() => {
      this.noHeader = value;
    }, 100);
  }
}
