import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { AnswerAmendRiskProfileRequest } from '@bp-core/src/lib/proto/amend-risk-profile.pb';
import { AmendRiskProfileGrpcServiceClient } from '@bp-core/src/lib/proto/amend-risk-profile.pbsc';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';
import { Observable } from 'rxjs';
import { BpConfirmationService } from '../services/bp-confirmation.service';

@Injectable({
  providedIn: 'root',
})
export class AcceptedRiskGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    private amendRiskProfileGrpcServiceClient: AmendRiskProfileGrpcServiceClient,
    private bpConfirmationService: BpConfirmationService,
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.AmendRiskProfileSubject$.subscribe({
      next: value => {
        if (
          value &&
          !value.answeredAt &&
          this.authService.userSubject$.value?.id == value.user?.id
        ) {
          this.bpConfirmationService.open({
            title: `Hola ${value?.user?.firstname}`,
            message: ` <p>Como parte de los procesos habituales de revisión de datos de nuestra plataforma, hemos chequeado nuevamente el cuestionario que respondiste al crearte un usuario en Betterplan, y tus respuestas arrojan que tu perfil de riesgo debiese ser <b> ${value.riskProfile?.title}.</b> <br> Si estás de acuerdo por favor haz click en Aceptar, de lo contrario puedes rechazar y un ejecutivo se contactará contigo.</p> `,
            icon: {
              color: 'primary',
              show: true,
              name: 'heroicons_outline:exclamation-circle',
            },
            actions: {
              confirm: {
                label: 'Aceptar',
                function: dialogRef => {
                  this.amendRiskProfileGrpcServiceClient
                    .answerRiskProfile(
                      new AnswerAmendRiskProfileRequest({
                        uuid: value.uuid,
                        userConfirmRiskProfile: true,
                      }),
                    )
                    .subscribe({
                      next: value => {
                        dialogRef.close();
                        this.authService.getStatus(this.authService.userSubject$.value!.id);

                        this.showConfirmSwal();
                      },
                      error: error => {
                        console.log(error);
                      },
                    });
                },
              },
              cancel: {
                label: 'Rechazar',
                show: true,
                function: dialogRef => {
                  this.amendRiskProfileGrpcServiceClient
                    .answerRiskProfile(
                      new AnswerAmendRiskProfileRequest({
                        uuid: value.uuid,
                        userConfirmRiskProfile: false,
                      }),
                    )
                    .subscribe({
                      next: value => {
                        dialogRef.close();

                        this.authService.getStatus(this.authService.userSubject$.value!.id);

                        this.showDenySwal();
                      },
                      error: error => {
                        console.log(error);
                      },
                    });
                },
              },
            },
          });
        }
      },
    });

    return true;
  }
  showConfirmSwal() {
    this.bpConfirmationService.open({
      title: '¡Excelente!',
      message: `<p>Gracias por confirmar tu perfil de riesgo</p>`,
      icon: {
        color: 'primary',
        show: true,
        name: 'heroicons_outline:check-circle',
      },
    });
  }
  showDenySwal() {
    this.bpConfirmationService.open({
      title: 'Gracias por tu respuesta',
      message: `<p> haz rechazado el perfil de riesgo que tienes como inversionista. <br /> Nos contactaremos contigo para actualizar esta información. </p>`,
      icon: {
        color: 'primary',
        show: true,
        name: 'heroicons_outline:check-circle',
      },
    });
  }
}
