import { Injectable } from '@angular/core';
import { FuseLoadingService } from '@fuse/services/loading';
import { GrpcEvent, GrpcMessage, GrpcRequest } from '@ngx-grpc/common';
import { GrpcHandler, GrpcInterceptor } from '@ngx-grpc/core';
import { Observable, finalize } from 'rxjs';

@Injectable()
export class GrpcLoadingInterceptor implements GrpcInterceptor {
  handleRequestsAutomatically: boolean;

  constructor(private _fuseLoadingService: FuseLoadingService) {
    // Subscribe to the auto
    this._fuseLoadingService.auto$.subscribe(value => {
      this.handleRequestsAutomatically = value;
    });
  }

  intercept<Q extends GrpcMessage, S extends GrpcMessage>(
    request: GrpcRequest<Q, S>,
    next: GrpcHandler,
  ): Observable<GrpcEvent<S>> {
    // If the Auto mode is turned off, do nothing
    if (!this.handleRequestsAutomatically) {
      return next.handle(request);
    }
    this._fuseLoadingService._setLoadingStatus(true, request.path);
    return next.handle(request).pipe(
      finalize(() => {
        // Set the status to false if there are any errors or the request is completed
        this._fuseLoadingService._setLoadingStatus(false, request.path);
      }),
    );
  }
}
