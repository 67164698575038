import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';

import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CardIconComponent } from './components/card-icon/card-icon.component';
import { CardLinkComponent } from './components/card-link/card-link.component';

import { ExplanationModalComponent } from './components/explanation-modal/explanation-modal.component';

import { TooltipChartComponent } from './components/tooltip-chart/tooltip-chart.component';

// Directives

// services
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BpCoreModule } from '@bp-core/src/public-api';
import { FuseAlertComponent } from '../../../../shared/src/template/@fuse/components/alert/alert.component';
import { BpAlertComponent } from './components/alert/bp-alert.component';

import { BpConfirmationModalComponent } from './components/bp-confirmation-modal/bp-confirmation-modal.component';
import { BpHeaderComponent } from './components/bp-header/bp-header.component';
import { CurrencySelectComponent } from './components/currency-select/currency-select.component';
import { ImageInfoCardComponent } from './components/image-info-card/image-info-card.component';
import { PersistentNotificationsComponent } from './components/persistent-notifications/persistent-notifications.component';
import { TimePeriodSelectComponent } from './components/time-period-select/time-period-select.component';
import { StickyClassDirective } from './directives/sticky-class.directive';
import { BpConfirmationService } from './services/bp-confirmation.service';
import { CurrencyIndicatorsService } from './services/currency-indicators.service';
import { FirstStepsService } from './services/first-steps.service';

@NgModule({
  declarations: [
    StickyClassDirective,
    BpConfirmationModalComponent,

    TooltipChartComponent,

    ImageInfoCardComponent,
    ExplanationModalComponent,
    CardIconComponent,
    CardLinkComponent,
    BpHeaderComponent,
    PersistentNotificationsComponent,
    BpAlertComponent,

    CurrencySelectComponent,
    TimePeriodSelectComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgScrollbarModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatMomentDateModule,
    MatToolbarModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    MatDividerModule,
    MatTabsModule,
    MatSortModule,
    MatTableModule,
    MatButtonToggleModule,

    ImageInfoCardComponent,
    ExplanationModalComponent,
    CardIconComponent,
    CardLinkComponent,

    BpHeaderComponent,
    PersistentNotificationsComponent,
    BpAlertComponent,
    StickyClassDirective,

    TooltipChartComponent,

    CurrencySelectComponent,
    TimePeriodSelectComponent,
  ],
  providers: [CurrencyPipe, BpConfirmationService, FirstStepsService, CurrencyIndicatorsService],
  imports: [
    MatPaginatorModule,
    MatTableModule,
    CommonModule,
    RouterModule,
    FormsModule,
    NgScrollbarModule,
    MatDialogModule,
    MatButtonModule,
    MatRippleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatMomentDateModule,
    MatToolbarModule,
    MatStepperModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    MatDividerModule,
    MatTabsModule,
    BpCoreModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatCheckboxModule,
    FuseAlertComponent,
    MatSlideToggleModule,
    MatSliderModule,
  ],
})
export class SharedModule {}
