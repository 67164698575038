import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Route, Router, UrlTree } from '@angular/router';
import { RedirectService } from './portal/redirect.service';

@Injectable()
export class CustomRouter extends Router {
  private isOldPortal: boolean;
  private appRoutes: string[];

  constructor(location: Location, private redirectService: RedirectService) {
    super();
    this.isOldPortal = location.path().startsWith('/legacy');
    this.appRoutes = this.getAppRoutes(this.config);
  }

  navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    if (this.isMigratedRoute(url)) {
      // Si estamos en el portal nuevo y la ruta es migrada, usamos super.navigate.
      // Si estamos en el portal antiguo y la ruta no es migrada, usamos super.navigate.
      if (!this.isOldPortal) {
        return super.navigateByUrl(url, extras);
      } else {
        this.redirectService.redirect(url.toString());
        return Promise.resolve(true);
      }
    } else {
      // Si estamos en el portal nuevo y la ruta no es migrada, usamos window.open.
      // Si estamos en el portal antiguo y la ruta es migrada, usamos window.open.
      if (this.isOldPortal) {
        return super.navigateByUrl(url, extras);
      } else {
        // Aquí deberías poner la URL completa del portal antiguo.
        this.redirectService.redirect('legacy' + url);

        return Promise.resolve(true);
      }
    }
  }
  private getMigratedRoutes(url: string): Record<string, boolean> {
    let routes: Record<string, boolean> = {};
    this.appRoutes.forEach(route => {
      routes[route] = true;
    });
    return routes;
  }
  private getAppRoutes(routes: Route[]): string[] {
    let routePaths: string[] = [];

    routes.forEach(route => {
      if (route.children) {
        routePaths = routePaths.concat(route.children.map(childRoute => childRoute.path!));
      }
    });
    routePaths.push('');
    return routePaths;
  }

  private isMigratedRoute(url: string | UrlTree): boolean {
    let route: string;

    if (typeof url === 'string') {
      route = url;
    } else if (url instanceof UrlTree) {
      route = url.toString();
    } else {
      return false;
    }

    let startRoute = '';
    if (route.split('/').length > 1) {
      startRoute = route.split('/')[1].split('#')[0];
    } else {
      startRoute = route.split('#')[0];
    }

    return this.getMigratedRoutes(route)[startRoute] || false;
  }
  //   private validateGoalRoute(url: string): boolean {
  //     if (url.includes('edit') || url.includes('start') || url.includes('invest')) {
  //       return false;
  //     }
  //     return true;
  //   }
}
