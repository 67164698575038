import { Inject, Injectable, InjectionToken, Optional, inject } from '@angular/core';
import { LogLevel, LumberjackService, LumberjackTimeService } from '@ngworker/lumberjack';
import { GrpcDataEvent, GrpcEvent, GrpcMessage, GrpcRequest } from '@ngx-grpc/common';
import { Observable, isObservable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GrpcHandler } from './grpc-handler';
import { GrpcInterceptor } from './grpc-interceptor';

export const GRPC_LOGGER_SETTINGS = new InjectionToken('GRPC_LOGGER_SETTINGS');

export interface GrpcLoggerSettings {
  enabled?: boolean;
  logClientSettings?: boolean;
  logMetadata?: boolean;
  logStatusCodeOk?: boolean;
  requestMapper?: (msg: GrpcMessage) => any;
  responseMapper?: (msg: GrpcMessage) => any;
}

@Injectable()
export class GrpcLoggerInterceptor implements GrpcInterceptor {
  readonly #lumberjack = inject(LumberjackService);
  readonly #time = inject(LumberjackTimeService);

  private static requestId = 0;
  private settings: GrpcLoggerSettings;

  constructor(@Optional() @Inject(GRPC_LOGGER_SETTINGS) settings: GrpcLoggerSettings = {}) {
    this.settings = {
      enabled: settings.enabled ?? true,
      logClientSettings: settings.logClientSettings ?? true,
      logMetadata: settings.logMetadata ?? true,
      logStatusCodeOk: settings.logStatusCodeOk ?? false,
      requestMapper: settings.requestMapper ?? ((msg: GrpcMessage) => msg.toObject()),
      responseMapper: settings.responseMapper ?? ((msg: GrpcMessage) => msg.toObject()),
    };
  }

  intercept<Q extends GrpcMessage, S extends GrpcMessage>(
    request: GrpcRequest<Q, S>,
    next: GrpcHandler,
  ): Observable<GrpcEvent<S>> {
    if (this.settings.enabled) {
      const id = ++GrpcLoggerInterceptor.requestId;
      const start = this.#time.getUnixEpochTicks();

      if (isObservable(request.requestData)) {
        request.requestData = request.requestData.pipe(
          tap(msg => {
            const duration = this.#time.getUnixEpochTicks() - start;
            this.colorLog(
              LumberjackLevel.Info,
              `${duration}ms -> ${request.path}`,
              'BP-GRPC',
              {
                id,
                type: 'Client Streaming',
                requestData: this.settings.requestMapper(msg),
              },
              '#eb0edc', // Color morado para Client Streaming
            );
          }),
        );
      }

      return next.handle(request).pipe(
        tap(event => {
          const logLevel = this.getLogLevel(event);
          if (logLevel !== null) {
            const duration = this.#time.getUnixEpochTicks() - start;
            const color = this.getColorForEvent(event);
            const eventType =
              event instanceof GrpcDataEvent
                ? 'Data'
                : event.statusCode !== 0
                  ? 'Error'
                  : 'StatusOk';
            this.colorLog(
              logLevel,
              `${duration}ms -> ${request.path}`,
              'BP-GRPC',
              {
                id,
                type: eventType,
                clientSettings: this.settings.logClientSettings
                  ? request.client.getSettings()
                  : undefined,
                metadata: this.settings.logMetadata
                  ? request.requestMetadata.toObject()
                  : undefined,
                request: isObservable(request.requestData)
                  ? '<see above>'
                  : this.settings.requestMapper(request.requestData),
                response:
                  event instanceof GrpcDataEvent ? this.settings.responseMapper(event.data) : event,
              },
              color,
            );
          }
        }),
      );
    }

    return next.handle(request);
  }

  private getLogLevel(event: GrpcEvent<any>): LogLevel | null {
    if (event instanceof GrpcDataEvent) {
      return LumberjackLevel.Info;
    } else if (event.statusCode !== 0) {
      return LumberjackLevel.Error;
    } else if (event.statusCode === 0 && this.settings.logStatusCodeOk) {
      return LumberjackLevel.Debug;
    }
    return null;
  }

  private getColorForEvent(event: GrpcEvent<any>): string {
    if (event instanceof GrpcDataEvent) {
      return '#5c7ced'; // Azul para Data
    } else if (event.statusCode !== 0) {
      return '#f00505'; // Rojo para Error
    } else {
      return '#0ffcf5'; // Cyan para StatusOk
    }
  }

  private colorLog(
    level: LogLevel,
    message: string,
    scope: string,
    payload: any,
    color: string,
  ): void {
    const timestamp = new Date().toISOString();
    const coloredMessage = `%c${level} ${timestamp} [${scope}] ${message}`;
    const style = `color: ${color}; font-weight: bold;`;

    // Solo imprimimos en la consola, no usamos Lumberjack
    console.log(coloredMessage, style, payload);
  }
}

enum LumberjackLevel {
  Critical = 'critical',
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  Trace = 'trace',
  Verbose = 'verbose',
  Warning = 'warn',
}
