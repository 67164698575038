import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';
import { Observable, catchError, throwError } from 'rxjs';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);

  // Clone the request object
  let newReq = req.clone();
  const headers: any = {};
  // Request
  //
  // If the access token didn't expire, add the Authorization header.
  // We won't add the Authorization header if the access token expired.
  // This will force the server to return a "401 Unauthorized" response
  // for the protected API routes which our response interceptor will
  // catch and delete the access token from the local storage while logging
  // the user out from the app.
  //console.log('token del intercet', authService.accessToken);
  if (authService.accessToken /*&& !AuthUtils.isTokenExpired(authService.accessToken)*/) {
    headers.Authorization = 'Bearer ' + authService.accessToken;
  }

  if (authService.bpEnterAsId) {
    headers.BPEnterAsId = authService.bpEnterAsId;
  }

  const authReq = !!authService.accessToken
    ? req.clone({
        setHeaders: headers,
      })
    : req;

  // Response
  return next(authReq).pipe(
    catchError(error => {
      // Catch "401 Unauthorized" responses
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Sign out
        //authService.signOut();
        // Reload the app
        //location.reload();
      }

      return throwError(error);
    }),
  );
};
