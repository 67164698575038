/* eslint-disable */
export const shortcuts = [
  {
    id: 'a1ae91d3-e2cb-459b-9be9-a184694f548b',
    label: 'Changelog',
    description: 'List of changes',
    icon: 'heroicons_outline:clipboard-document-list',
    link: '/docs/changelog',
    useRouter: true,
  },
  {
    id: '989ce876-c177-4d71-a749-1953c477f825',
    label: 'Documentation',
    description: 'Getting started',
    icon: 'heroicons_outline:book-open',
    link: '/docs/guides/getting-started/introduction',
    useRouter: true,
  },
  {
    id: '2496f42e-2f25-4e34-83d5-3ff9568fd984',
    label: 'Help center',
    description: 'FAQs and guides',
    icon: 'mat_outline:help_outline',
    link: '/apps/help-center',
    useRouter: true,
  },
  {
    id: '3c48e75e-2ae7-4b73-938a-12dc655be28b',
    label: 'Dashboard',
    description: 'User analytics',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboards/analytics',
    useRouter: true,
  },
  {
    id: '2daac375-a2f7-4393-b4d7-ce6061628b66',
    label: 'Mailbox',
    description: '5 new e-mails',
    icon: 'heroicons_outline:envelope',
    link: 'apps/mailbox',
    useRouter: true,
  },
  {
    id: '56a0a561-17e7-40b3-bd75-0b6cef230b7e',
    label: 'Tasks',
    description: '12 unfinished tasks',
    icon: 'heroicons_outline:check-circle',
    link: '/apps/tasks',
    useRouter: true,
  },
  {
    id: 'f5daf93e-b6f3-4199-8a0c-b951e92a6cb8',
    label: 'Contacts',
    description: 'List all contacts',
    icon: 'heroicons_outline:user-group',
    link: '/apps/contacts',
    useRouter: true,
  },
  {
    id: '0a240ab8-e19d-4503-bf68-20013030d526',
    label: 'Reload',
    description: 'Reload the app',
    icon: 'heroicons_outline:arrow-path',
    link: '/dashboards/project',
    useRouter: false,
  },
];
