<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'empty') {
  <empty-layout></empty-layout>
}
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Bp Steps layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
@if (layout === 'bpstepsheader') {
  <bp-steps-layout></bp-steps-layout>
}
<!-- Bp -->
@if (layout === 'bp') {
  <bp-layout></bp-layout>
}

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- <settings></settings> -->
