<div class="relative flex flex-col w-full h-full">
  <!-- Dismiss button -->
  @if (data.dismissible) {
    <div class="absolute top-0 right-0 pt-4 pr-4">
      <button mat-icon-button [matDialogClose]="undefined">
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
      </button>
    </div>
  }

  <!-- Content -->
  <div
    class="flex flex-col items-start flex-auto w-full overflow-auto sm:flex-row sm:items-start"
    [ngClass]="{ 'p-0 ': data.noPadding, 'p-8 pb-6  sm:pb-8': !data.noPadding }"
  >
    <!-- Icon -->
    @if (data.icon?.show) {
      <div
        class="items-center justify-center hidden w-10 h-10 rounded-full sm:flex flex-0 sm:mr-4"
        [ngClass]="{
          'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600':
            data.icon?.color === 'primary',
          'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600':
            data.icon?.color === 'accent',
          'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600':
            data.icon?.color === 'warn',
          'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600':
            data.icon?.color === 'basic',
          'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600':
            data.icon?.color === 'info',
          'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500':
            data.icon?.color === 'success',
          'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500':
            data.icon?.color === 'warning',
          'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon?.color === 'error'
        }"
      >
        @if (data.icon) {
          <mat-icon class="text-current" [svgIcon]="data.icon?.name!"></mat-icon>
        }
      </div>
    }
    @if (data.title || data.message) {
      <div
        class="flex flex-col items-start w-full h-full mt-4 space-y-1 overflow-x-hidden text-left sm:items-start sm:mt-0 sm:text-left"
        [ngClass]="{ 'overflow-y-hidden': data.noYScroll, 'overflow-y-auto': !data.noYScroll }"
      >
        <!-- Title -->
        @if (data.title || data.title !== '') {
          <div
            [ngClass]="{ 'ml-6': data.noPadding }"
            class="mb-1 text-xl font-medium leading-6"
            [innerHTML]="data.title"
          ></div>
        }
        <!-- Message -->
        @if (isBodyTemplate()) {
          <!-- Message -->
          <ng-container [ngTemplateOutlet]="data.bodyTemplate!"></ng-container>
        }
        @if (isBodyComponent()) {
          <div class="w-full h-full">
            <!-- <ng-container *ngComponentOutlet="data.bodyComponent!"></ng-container> -->
            <ng-container #componentContainer></ng-container>
          </div>
        }
        @if (data.message && !data.bodyTemplate && !data.bodyComponent) {
          <div [innerHTML]="data.message"></div>
        }
      </div>
    }
  </div>

  <!-- Actions -->
  @if (data.actions?.confirm?.show || data.actions?.cancel?.show) {
    <div
      class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50 dark:bg-black dark:bg-opacity-10"
    >
      <!-- Cancel -->
      @if (data.actions?.cancel?.show) {
        @if (!data.actions?.cancel?.function) {
          <button mat-stroked-button [matDialogClose]="'cancelled'">
            {{ data.actions?.cancel?.label }}
          </button>
        }
        <br />
        @if (data.actions?.cancel?.function) {
          <button mat-stroked-button (click)="cancelAction()" [disabled]="disableCancelButton">
            {{ data.actions?.cancel?.label }}
          </button>
        }
      }
      <!-- Confirm -->
      @if (data.actions?.confirm?.show) {
        @if (!data.actions?.confirm?.function) {
          <button
            mat-flat-button
            [color]="data.actions?.confirm?.color"
            [matDialogClose]="'confirmed'"
          >
            {{ data.actions?.confirm?.label }}
          </button>
        }
        @if (data.actions?.confirm?.function) {
          <button
            mat-flat-button
            [color]="data.actions?.confirm?.color"
            [disabled]="disableConfirmButton"
            (click)="confirmAction()"
          >
            {{ data.actions?.confirm?.label }}
          </button>
        }
      }
    </div>
  }
</div>
