import { ComponentRef, Type, ViewContainerRef } from '@angular/core';

export class ComponentFactoryService {
  private viewContainerRef: ViewContainerRef;

  setViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  createComponent<T>(component: Type<T>, inputs?: Partial<T>): ComponentRef<T> {
    // retorna ComponentRef<T> en lugar de Type<T>
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(component);

    if (inputs) {
      for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
          (componentRef.instance as any)[key] = inputs[key];
        }
      }
    }

    // Forzar la detección de cambios
    componentRef.changeDetectorRef.detectChanges();

    return componentRef;
  }
}
