import { Injectable } from '@angular/core';
import {
  GetMeNotificationsTransactionsRequest,
  NotificationsTransactionsPending,
  SummaryNotificationsTransactionsPending,
} from '@bp-core/src/lib/proto/portal-user.pb';
import { PortalUserGrpcServiceClient } from '@bp-core/src/lib/proto/portal-user.pbsc';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BpPendingNotificationsService {
  private summaryPendingNotificationsSubject = new ReplaySubject<
    SummaryNotificationsTransactionsPending[] | undefined
  >(1);
  public readonly summaryPendingNotifications$ = this.summaryPendingNotificationsSubject.asObservable();

  private pendingNotificationsSubject = new ReplaySubject<
    NotificationsTransactionsPending[] | undefined
  >(1);
  public readonly pendingNotifications$ = this.pendingNotificationsSubject.asObservable();

  constructor(private portalUserGrpcServiceClient: PortalUserGrpcServiceClient) {}

  getSummaryPendingNotifications() {
    let getMeSummaryPendingNotificationsRequest = new GetMeNotificationsTransactionsRequest();

    this.portalUserGrpcServiceClient
      .getMeSummaryNotificationsTransactionsPending(getMeSummaryPendingNotificationsRequest)
      .subscribe({
        next: notifications => {
          this.summaryPendingNotificationsSubject.next(notifications.values);
        },
        error: e => {
          console.error(e);
        },
      });
  }

  getPendingNotifications() {
    let getMeSummaryPendingNotificationsRequest = new GetMeNotificationsTransactionsRequest();

    this.portalUserGrpcServiceClient
      .getMeNotificationsTransactionsPending(getMeSummaryPendingNotificationsRequest)
      .subscribe({
        next: notifications => {
          this.pendingNotificationsSubject.next(notifications.values);
        },
        error: e => {
          console.error(e);
        },
      });
  }
}
