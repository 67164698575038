import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BpHeaderConfig } from '../models/header-config';

@Injectable({
  providedIn: 'root',
})
export class BpHeaderService {
  private _headerConfig = new BehaviorSubject<BpHeaderConfig | null>(null);

  headerConfig$ = this._headerConfig.asObservable();

  setHeaderConfig(config: BpHeaderConfig) {
    this._headerConfig.next(config);
  }

  clearHeaderConfig() {
    this._headerConfig.next(null);
  }
}
